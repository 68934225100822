import { useQuery } from '@tanstack/react-query'
import { Fade } from 'react-awesome-reveal'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'

import { DadosFn } from '@/api/dados'
import { GetParceirosFn } from '@/api/getParceiros'
import { GetPatrocinadoresFn } from '@/api/getPatrocinadores'
import { CountUpCard } from '@/components/count-up-card'
import { HomeCard } from '@/components/home-card'
import { SlideHome } from '@/components/slide-home'
import { Button } from '@/components/ui/button'
import { DadosDTO } from '@/dtos/DadosDTO'
import { ParceirosDTO } from '@/dtos/ParceirosDTO'
import { PatrocinadoresDTO } from '@/dtos/PatrocinadoresDTO'

import eventos from '../../assets/eventos.webp'
import oficinas from '../../assets/oficinas.webp'
import palestras from '../../assets/palestras.webp'

export function Home() {
  // const { showPopUP, setShowPopUP } = useMain()

  const { data: dados } = useQuery({
    queryKey: ['dados'],
    queryFn: () => DadosFn(),
  })

  const { data: patrocinadores } = useQuery({
    queryKey: ['patrocinadores'],
    queryFn: () => GetPatrocinadoresFn(),
  })

  const { data: parceiros } = useQuery({
    queryKey: ['parceiros'],
    queryFn: () => GetParceirosFn(),
  })

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowPopUP(true)
  //   }, 1000)
  // }, [])

  return (
    <>
      {/* {showPopUP && <PopUp />} */}
      <section className="grid w-full grid-cols-1 max-[1024px]:pt-20 lg:grid-cols-3 min-[1024px]:h-screen min-[1025px]:pt-20 min-[1450px]:pt-0">
        <HomeCard
          bgColor="bg-pink-650"
          hoverBgColor="hover:bg-purple-950"
          icon="icon1"
          iconColor="bg-purple-950"
          textColor="text-white"
          hoverTextColor="text-white"
          text={`Preciso de \numa peruca`}
          borderColor="border-white"
          btnText="Solicite aqui"
          link="/quero-uma-peruca"
          time={200}
        />
        <HomeCard
          bgColor="bg-white"
          hoverBgColor="hover:bg-pink-650"
          icon="icon2"
          iconColor="bg-pink-650"
          textColor="text-purple-950"
          hoverTextColor="hover:text-white"
          text={`Ajude alguém a \nsorrir novamente`}
          borderColor="border-purple-950"
          btnText="Saiba como"
          link="/como-ajudar"
          time={200}
        />
        <HomeCard
          bgColor="bg-purple-950"
          hoverBgColor="hover:bg-white"
          icon="icon3"
          iconColor="bg-white"
          textColor="text-white"
          hoverTextColor="hover:text-purple-950"
          text={`Acompanhe seu \ncertificado de doação`}
          borderColor="border-white"
          btnText="Acompanhe aqui"
          link="/certificado"
          time={200}
        />
      </section>
      {/* bg-gradient-to-r from-purple-950 from-25% via-white via-25% to-white to-50% */}
      <section className="flex items-center justify-center max-[1024px]:py-20 max-[480px]:py-4">
        <div className="container">
          <div className="flex items-center justify-between max-[1200px]:gap-8 max-[1024px]:flex-col">
            <div className="flex flex-col items-center justify-center gap-4 bg-purple-950 pb-12">
              <div className="w-[35rem] max-[1200px]:w-[30rem] max-[580px]:w-[20rem]">
                <SlideHome />
              </div>
              <Fade delay={200}>
                <h2 className="font-roboto text-4xl font-bold text-white max-[580px]:text-center">
                  Peruca não é <br className="hidden max-[580px]:block" /> só
                  cabelo
                </h2>
              </Fade>
              <hr className="w-2/3 border-[1px] border-white" />
              <Fade delay={200}>
                <h3 className="text-center font-roboto text-base font-normal text-white max-[480px]:text-2xl">
                  Sua doação passa por uma etapa muito importante{' '}
                  <br className="block max-[580px]:hidden" /> para se
                  transformar em autoestima:
                </h3>
                <Link to="/confeccao-de-perucas">
                  <Button
                    variant="transparent"
                    size="lg"
                    className="border-white font-roboto text-xl font-medium text-white hover:bg-white hover:text-purple-950"
                  >
                    Saiba mais
                  </Button>
                </Link>
              </Fade>
            </div>
            <div className="flex max-w-[35rem] flex-col items-end justify-end gap-8 text-right max-[1024px]:max-w-full max-[1024px]:items-center max-[1024px]:justify-center max-[1024px]:pt-8 max-[1024px]:text-center">
              <Fade delay={200}>
                <h2 className="font-roboto text-3xl font-bold text-purple-950 max-[1200px]:text-xl max-[480px]:text-2xl">
                  Desde 2013, devolvendo a autoestima de pessoas em tratamento
                  contra o câncer
                </h2>
                <p className="font-roboto text-lg font-normal text-purple-950 max-[1200px]:text-sm max-[480px]:text-base">
                  A Cabelegria é uma ONG que arrecada recursos para transformar
                  as milhares de doações de cabelo recebidas em lindas perucas e
                  as doa gratuitamente à pacientes oncológicos, através dos
                  Correios e Bancos de Perucas (Fixos e Itinerantes). <br />
                  <br /> Para nós, a peruca não é apenas um simples acessório,
                  mas sim a restauração da autoestima e da confiança para
                  aqueles que lutam contra a doença.
                  <br />
                  <br />
                  Ao longo da jornada pudemos comprovar o quanto a autoestima é
                  fator essencial para qualidade de vida e, por isso, hoje,
                  buscamos promovê-la de diversas maneiras além da doação de
                  perucas, com ações de empoderamento feminino, capacitação e
                  geração de renda.
                </p>
                <Link to="/a-ong">
                  <Button
                    variant="transparent"
                    size="lg"
                    className="border-purple-950 font-roboto text-xl font-medium text-purple-950 hover:bg-purple-950 hover:text-white"
                  >
                    Saiba mais
                  </Button>
                </Link>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section className="flex items-center justify-center bg-purple-950 max-[1024px]:py-20">
        <div className="container">
          <div className="grid grid-cols-auto-fit-160 items-center gap-4 py-12 max-[1024px]:justify-items-center max-[767px]:gap-12 max-[480px]:grid-cols-1 max-[480px]:gap-6 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
            {dados?.map((dadosData: DadosDTO) => {
              return (
                <Fade delay={200} key={dadosData.d_id}>
                  <CountUpCard
                    icon={dadosData.d_icon}
                    text={dadosData.d_title}
                    countEnd={dadosData.d_number}
                    decimals={Number(dadosData.d_decimal)}
                    delay={2}
                  />
                </Fade>
              )
            })}
            <div className="max-[1024px]:text-center min-[481px]:col-span-2 sm:pt-10 lg:col-span-4 lg:text-center xl:col-span-1 xl:text-left">
              <Fade delay={200}>
                <h2 className="pb-8 font-roboto text-4xl font-bold text-white max-[1024px]:text-center max-[480px]:text-3xl">
                  Nos ajude a aumentar esses números
                </h2>
                <Link to="/como-ajudar">
                  <Button
                    variant="transparent"
                    size="lg"
                    className="font-roboto text-xl font-medium text-white hover:bg-white hover:text-purple-950 min-[481px]:col-span-2 min-[655px]:col-span-3 min-[1024px]:col-span-1"
                  >
                    Saiba Como
                  </Button>
                </Link>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section className="flex items-center justify-center bg-pink-650 py-40 max-[1024px]:py-20">
        <div className="container">
          <div className="flex gap-16 max-[1024px]:flex-col max-[1024px]:gap-4 min-[1400px]:justify-between">
            <div className="flex flex-col items-center justify-center">
              <div className="relative mx-auto h-[398px] w-full max-[480px]:h-[192px] min-[1025px]:h-[280px] min-[1025px]:w-[500px] min-[1200px]:h-[350px] min-[1200px]:w-[650px] min-[1400px]:h-[398px] min-[1400px]:w-[850px] min-[1600px]:h-[450px] min-[1600px]:w-[1000px]">
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=PlhjVLskkAs"
                  light={true}
                  width="100%"
                  height="100%"
                />

                {/* <iframe
                  src="https://www.youtube.com/embed/PlhjVLskkAs?rel=0&amp;showinfo=0"
                  title="Seja a diferença na vida de pacientes com câncer"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  className="h-full w-full"
                ></iframe> */}
              </div>
            </div>
            <div className="flex flex-col items-start justify-center gap-6 max-[1024px]:items-center max-[1024px]:pt-5 min-[1025px]:max-w-[24rem]">
              <Fade delay={200}>
                <h2 className="font-roboto text-5xl font-bold text-white max-[1024px]:text-center max-[480px]:text-3xl">
                  Ajude alguém a sorrir novamente
                </h2>
                <Link to="/como-ajudar">
                  <Button
                    variant="transparent"
                    size="lg"
                    className="font-roboto text-xl font-medium text-white hover:bg-white hover:text-pink-650"
                  >
                    Saiba Como
                  </Button>
                </Link>
              </Fade>
              {/* <Button
                variant="transparent"
                size="lg"
                className="font-roboto text-xl font-medium text-white hover:bg-white hover:text-pink-650 max-[480px]:h-20 lg:h-20"
              >
                Saiba Mais Sobre a
                <br className="hidden max-[480px]:block lg:block" /> Confecção
                das Perucas
              </Button> */}
            </div>
          </div>
          <div className="pt-12 lg:flex lg:flex-col lg:items-center lg:justify-center">
            <h2 className="font-roboto text-5xl font-bold text-white max-[1024px]:text-center max-[1024px]:text-4xl max-[480px]:text-3xl">
              As perucas são só uma parte do nosso trabalho
            </h2>
            <div className="flex flex-col gap-6 max-[1024px]:pt-5">
              <div className="grid grid-cols-auto-fit-160 items-center gap-16 pt-12 max-[1024px]:justify-items-center max-[767px]:gap-12 max-[480px]:grid-cols-1 max-[480px]:gap-6 lg:grid-cols-4">
                <Fade delay={200}>
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={oficinas}
                      alt="Oficinas"
                      className="h-44 w-44 rounded-full"
                    />
                    <span className="py-4 font-roboto text-xl font-medium text-white">
                      Oficinas
                    </span>
                  </div>
                </Fade>
                <Fade delay={200}>
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={eventos}
                      alt="Eventos"
                      className="h-44 w-44 rounded-full"
                    />
                    <span className="py-4 font-roboto text-xl font-medium text-white">
                      Eventos
                    </span>
                  </div>
                </Fade>
                <Fade delay={200}>
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={palestras}
                      alt="Palestras"
                      className="h-44 w-44 rounded-full"
                    />
                    <span className="py-4 font-roboto text-xl font-medium text-white">
                      Palestras
                    </span>
                  </div>
                </Fade>
                <Fade delay={200}>
                  <Link to="/como-ajudar">
                    <Button
                      variant="transparent"
                      size="lg"
                      className="font-roboto text-xl font-medium text-white hover:bg-white hover:text-pink-650 min-[481px]:col-span-2 min-[655px]:col-span-3 min-[1024px]:col-span-1"
                    >
                      Saiba Mais
                    </Button>
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex items-center justify-center py-20">
        <div className="container">
          <h2 className="font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-center">
            Patrocinadores
          </h2>
          <div className="grid w-[744px] grid-cols-4 gap-8 py-12 max-[800px]:w-full max-[800px]:grid-cols-2 max-[480px]:grid-cols-1">
            {patrocinadores?.map((patrocinador: PatrocinadoresDTO) => {
              return (
                <Fade
                  key={patrocinador.p_id}
                  direction="left"
                  className="max-[800px]:mx-auto"
                >
                  <Link to={patrocinador.p_site} target="_blank">
                    <img
                      src={`assets/patrocinadores/${patrocinador.p_logo}`}
                      alt={`Logo ${patrocinador.p_name}`}
                      className="imageFilter h-[162px] w-[162px] rounded-full border border-purple-950 p-3"
                    />
                  </Link>
                </Fade>
              )
            })}
          </div>
          <h2 className="font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-center">
            Parceiros
          </h2>
          <div className="grid grid-cols-auto-fit-160 items-center gap-8 py-12 max-[480px]:grid-cols-1">
            {parceiros?.map((parceiro: ParceirosDTO) => {
              return (
                <Fade
                  key={parceiro.p_id}
                  direction="left"
                  className="max-[622px]:mx-auto"
                >
                  <Link to={parceiro.p_site} target="_blank">
                    <img
                      src={`assets/parceiros/${parceiro.p_logo}`}
                      alt={`Logo ${parceiro.p_name}`}
                      className="imageFilter h-[162px] w-[162px] rounded-full border border-purple-950 p-3"
                    />
                  </Link>
                </Fade>
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}
