import { FaCheckCircle } from 'react-icons/fa'

export function DoeCabeloList() {
  return (
    <ul className="flex flex-col gap-4">
      <li className="flex gap-4">
        <span>
          <FaCheckCircle color="#e6007e" size={25} />
        </span>
        <p className="font-roboto text-lg font-normal text-purple-950 max-[560px]:text-base">
          <strong>1.</strong> Lave e deixe seu cabelo{' '}
          <br className="hidden max-[560px]:hidden lg:block" />
          secar naturalmente
        </p>
      </li>
      <li className="flex gap-4">
        <span>
          <FaCheckCircle color="#e6007e" size={25} />
        </span>
        <p className="font-roboto text-lg font-normal text-purple-950 max-[560px]:text-base">
          <strong>2.</strong> Faça um rabo de cavalo{' '}
          <br className="hidden max-[560px]:hidden lg:block" />e amarre com
          elástico de borracha
        </p>
      </li>
      <li className="flex gap-4">
        <span>
          <FaCheckCircle color="#e6007e" size={25} />
        </span>
        <p className="font-roboto text-lg font-normal text-purple-950 max-[560px]:text-base">
          <strong>3.</strong> Coloque a mecha em um saco{' '}
          <br className="hidden max-[560px]:hidden lg:block" />
          plástico para melhor manuseio
        </p>
      </li>
      <li className="flex gap-4">
        <span>
          <FaCheckCircle color="#e6007e" size={25} />
        </span>
        <p className="font-roboto text-lg font-normal text-purple-950 max-[560px]:text-base">
          <strong>4.</strong>
          <strong>
            Seu cabelo está pronto para{' '}
            <br className="hidden max-[560px]:hidden lg:block" />
            ser enviado!{' '}
          </strong>
        </p>
      </li>
      <li className="flex gap-4">
        <span>
          <FaCheckCircle color="#e6007e" size={25} />
        </span>
        <p className="font-roboto text-lg font-normal text-purple-950 max-[560px]:text-base">
          <strong>5.</strong> Ainda tem dúvidas?{' '}
          <br className="hidden max-[560px]:hidden lg:block" />
          <a href="/duvidas-frequentes">Clique aqui</a>.
        </p>
      </li>
    </ul>
  )
}
