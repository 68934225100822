import { useEffect } from "react";

import { useMain } from "@/contexts/mainContext";
import { api } from "@/lib/axios";

export function QueroUmaPerucaFormStep6() {
  const { data, status, setStatus } = useMain();

  useEffect(() => {
    async function sendData() {
      const formData = new FormData();

      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("rg", data.rg);
      formData.append("cpf", data.CPF);
      formData.append("cep", data.cep);
      formData.append("address", data.address);
      formData.append("number", data.number);
      formData.append("complement", data.complement);
      formData.append("cityUF", data.cityUF);
      formData.append("hospital", data.hospital);
      formData.append("perucaIdealTipo", data.perucaIdealTipo);
      formData.append("perucaIdealComprimento", data.perucaIdealComprimento);
      formData.append("perucaIdealCor", data.perucaIdealCor);
      formData.append("comoficousabendo", data.comoficousabendo);

      for (let i = 0; i < data?.docs?.length; i++) {
        formData.append("docs[]", data.docs[i]);
      }

      const response = await api.post("/solicitacao", formData);

      setStatus(response.status);
    }

    sendData();
  }, [data]);

  return (
    <>
      {status === 0 ? (
        ""
      ) : (
        <div className="rounded-md border-[3px] border-pink-650 p-4 max-[1279px]:p-4 max-[1024px]:h-auto">
          <div className="flex flex-col gap-4">
            <div className="block font-roboto text-sm font-normal text-pink-650 max-[1279px]:text-center max-[480px]:text-sm">
              <span className="block pb-1">
                Nossa equipe entrará em contato em até{" "}
                <strong>5 dias úteis</strong> para dar seguimento ao seu pedido!
              </span>
              <strong>
                Fique de olho no seu e-mail, entraremos em contato por lá!
              </strong>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
