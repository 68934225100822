import { api } from '@/lib/axios'

export interface ProtocoloData {
  name: string
}

export async function ProtocoloFn({ name }: ProtocoloData) {
  const response = await api.get(`protocol?name=${name}`)

  return response.data
}
